@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .navigationStyle{
        @apply font-Manrope font-bold text-primary flex justify-between px-7 py-8 items-center fixed w-full max-w-screen-desktop mx-auto desktop:pl-24 desktop:pr-28 z-50
    }
    .btnstyle{
        @apply font-Manrope font-bold text-2xl text-white btnBG py-6 w-full text-center rounded-2xl mt-5
    }
}

html {
    scroll-behavior: smooth;
  }

.hero{
    background-image: url('./Asset/HeroBG.png');
    background-repeat: no-repeat;
    background-size: cover ;
}
.btnBG{
    background-image: linear-gradient(to top right, #FCD9FB -65% , #7342E4 95%)
}
.serviceBG{
    background-image: url('./Asset/serviceBg.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.selectionstyle{
    position: relative;
    width: 240px;
    height: 54px;
}
.selectionstyle::before{
    content: '/';
    background: url('./Asset/globe.svg');
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    width: 30px;
    height: 32px;
}
.selectionstyle::after{
    content: '/';
    background: url('./Asset/arrowDown.svg') no-repeat;
    position: absolute;
    width: 16px;
    top: 50%;
    right: 5%;
    transform: translateY(-25%);
    pointer-events: none;
}